import React, { Component } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import { Link } from "react-router-dom";
import FooterTwo from "../../component/footer/FooterTwo";

class AuditServices extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        {/* <PageHelmet pageTitle="Service Details" /> */}
        {/* End Pagehelmet  */}

        {/* <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        /> */}

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pb--50 bg_image bg_image--audit"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-sm-12 d-flex align-items-center">
                <div className="logo">
                  <Link to="/">
                    <img
                      src="/assets/images/logo/americas-logo.png"
                      alt="Logo"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-lg-9 col-sm-12">
                <div className="rn-page-title pt--50 align-left">
                  <h2 className="title title-white">Telecom Audit Services</h2>
                  <p>Validating to improve</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src="/assets/images/service/audit/content.jpg"
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>
                            Our team offers a wide range of services related to
                            audits of telecom projects such as:
                          </p>
                          {/* <h5 className="title">
                            RF Design of Point to Point Networks and Microwave
                            Backhault Network that includes:
                          </h5> */}
                          <ul className="liststyle">
                            <li>
                              Spectrum analysis and interference assessment
                            </li>
                            <li>Datafill validating and cross checks</li>
                            <li>Walk testing (indoor environments)</li>
                            <li>Drive testing (outdoor environments)</li>
                            <li>
                              Telecom buildings and shelter space availability
                            </li>
                            <li>
                              As built installation validation
                              <li className="subliststyle">Antennas</li>
                              <li className="subliststyle">Cable</li>
                              <li className="subliststyle">Equipment</li>
                            </li>
                            <li>Sweep test analysis and validation</li>
                            <li>Path alignment</li>
                            <li>IP and ethernet testing</li>
                            <li>RF and network parameters</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area 
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <h5 className="title">
                            RF Design of Wireless Mobile Networks: 2G, 3G, 4G
                            and Point to Multi-Point Networks: Wifi, Fixed
                            WiMax, SCADA:
                          </h5>
                          <ul className="liststyle">
                            <li>Site survey</li>
                            <li>Coverage and capacity planning</li>
                            <li>In building/DAS solutions</li>
                            <li>Frequency planning</li>
                            <li>PN/Code planning</li>
                            <li>Neighbor list planning</li>
                            <li>Link bidget</li>
                            <li>Equipment selection</li>
                            <li>Interference solutions</li>
                            <li>Spectrum allocation and licensing</li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src="/assets/images/service/service-02.png"
                            alt="Service Images"
                          />
                          <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen}
                            videoId="ZOoVOfieAF8"
                            onClose={() => this.setState({ isOpen: false })}
                          />
                          <button
                            className="video-popup"
                            onClick={this.openModal}
                          >
                            <span className="play-icon"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <FooterTwo />
      </React.Fragment>
    );
  }
}
export default AuditServices;
