import React, { Component, Fragment } from "react";

import ScrollToTop from "react-scroll-up";
import { FiChevronUp, FiMapPin, FiMail, FiPhoneCall } from "react-icons/fi";

import FooterTwo from "../component/footer/FooterTwo";

import Slider from "react-slick";
import { portfolioSlick2 } from "../page-demo/script";

import SliderWithVideo from "../component/slider/SliderWIthVideo";
import About from "../component/layout/About";
import HeaderThree from "../component/header/HeaderThree";
import Team from "../elements/Team";

const PortfolioList2 = [
  {
    image: "image-4",
    link: "/design",
    title: " Design services",
  },
  {
    image: "image-3",
    link: "/professional",
    title: " Professional services",
  },
  {
    image: "image-5",
    link: "/management",
    title: " Project and program management services",
  },
  {
    image: "image-1",
    link: "/deployment",
    title: " Installation and deployment services",
  },

  {
    image: "image-2",
    link: "/audit",
    title: " Telecom audit services",
  },

  {
    image: "image-6",
    link: "/regulatory",
    title: " Government planning and regulatory",
  },
];

class AmericasRF extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  stickyHeader() {}
  render() {
    return (
      <Fragment>
        <HeaderThree homeLink="/" />

        {/* Start Slider Area  */}
        <div id="home">
          {/*  <SliderFour /> */}
          <SliderWithVideo />
        </div>
        {/*End Slider Area   */}

        {/* Start About Area  about-position-top*/}
        <div id="about" className="about-area  ptb--120 bg_color--5">
          <About />
        </div>
        {/* End About Area */}

        {/* Start Services Area ptb--120 */}
        <div className="portfolio-area ptb--120 bg_color--5" id="service">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title text-left service-style--3 mb--30">
                  <h2 className="title">Our services</h2>
                  <p>
                    We have extensive experience delivering telecom solutions in
                    rural and remote areas. Our objective is to close the
                    existing digital divide.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper portfolio-sacousel-inner mb--55">
            <div className="portfolio-slick-activation mt--30 mt_sm--30">
              <Slider {...portfolioSlick2}>
                {PortfolioList2.map((value, index) => (
                  <div
                    className="portfolio portfolio-interior-design"
                    key={index}
                  >
                    <div className="thumbnail-inner">
                      <div className={`thumbnail ${value.image}`}></div>
                      <div className={`bg-blr-image ${value.image}`}></div>
                    </div>
                    <div className="content">
                      <div className="inner">
                        <h4>
                          <a href={value.link}>{value.title}</a>
                        </h4>
                        <div className="portfolio-button">
                          <a className="rn-btn" href={value.link}>
                            View more
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
        {/* End Portfolio Area */}

        {/* Start Team Area  */}
        <div className="rn-team-area ptb--120 bg_color--5" id="team">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                  <h2 className="title">Meet our team</h2>
                  <p>Professionals behind our success</p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <Team column="col-lg-3 col-md-3 col-sm-3 col-12" />
            </div>
          </div>
        </div>
        {/* End Team Area  */}

        {/* Start Contact Top Area  */}
        <div className="rn-contact-top-area ptb--120 bg_color--5" id="contact">
          <div className="container">
            <div className="row">
              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="rn-address">
                  <a href="tel:+573205493740">
                    <div className="icon">
                      <FiPhoneCall />
                    </div>
                  </a>
                  <div className="inner">
                    <h4 className="title">Phone number</h4>
                    <p>
                      <br />
                      <a href="tel:+16023041760">+1 602 304 1760</a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                <div className="rn-address">
                  <a href="mailto:info@americasrf.com">
                    <div className="icon">
                      <FiMail />
                    </div>
                  </a>
                  <div className="inner">
                    <h4 className="title">Email</h4>
                    <p>
                      <br />
                      <a href="mailto:info@americasrf.com">
                        info@americasrf.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                <div className="rn-address">
                  <a
                    href="https://www.google.com/maps/place/6100+W+Gila+Springs+Pl+STE+7,+Chandler,+AZ+85226,+EE.+UU./@33.3022402,-111.9499903,17z/data=!3m1!4b1!4m5!3m4!1s0x872b06a96750ab71:0x7523d02e7b1e61e8!8m2!3d33.3022357!4d-111.9478016?hl=es"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="icon">
                      <FiMapPin />
                    </div>
                  </a>
                  <div className="inner">
                    <h4 className="title">Address</h4>
                    <p>
                      6100 W, Gila Springs Place, Suite 7 <br /> Chandler, AZ,
                      85226-3539
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}
            </div>
          </div>
        </div>
        {/* End Contact Top Area  */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default AmericasRF;
