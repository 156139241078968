import React, { Component } from "react";
//import ModalVideo from "react-modal-video";
import { videoTagString, VideoTag } from "react-video-tag";
videoTagString({
  src: "/assets/images/bg/video.mp4",
  poster: "/assets/images/bg/bg-image-1.jpg",
});

const SlideList = [
  {
    textPosition: "text-left",
    category: "",
    title: "Americas Communications",
    description:
      "Our team has the expertise and willingness that will make your company succeed.",
    buttonText: "Contact Us",
    buttonLink: "#contact",
  },
];

class SliderWithVideo extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  re;
  render() {
    return (
      <div className="slider-wrapper">
        {/* Start Single Slide */}
        {SlideList.map((value, index) => (
          <div
            className="slide slide-style-2 slider-video-bg d-flex align-items-center justify-content-center"
            key={index}
            data-black-overlay="4"
          >
            <div className="video-background">
              <VideoTag
                className="custom-video"
                autoPlay={`${true}`}
                muted={`${true}`}
                playsInline={`${true}`}
                loop={`${true}`}
                src={`${"/assets/images/bg/video-2.mp4"}`}
                poster={`${"/assets/images/bg/bg-image-1.jpg"}`}
              />
            </div>
          </div>
        ))}
        {/* End Single Slide */}
      </div>
    );
  }
}
export default SliderWithVideo;
