// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";

// Create Import File
import "./index.scss";

import error404 from "./elements/error404";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import AmericasRF from "./home/AmericasRF";
import DesignServices from "./home/services/DesignServices";
import ProfessionalServices from "./home/services/ProfessionalServices";
import DeploymentServices from "./home/services/DeploymentServices";
import AuditServices from "./home/services/AuditServices";
import ManagementServices from "./home/services/ManagementServices";
import RegulatoryServices from "./home/services/RegulatoryServices";
import HomeParticles from "./home/HomeParticles";
import JobOffer from "./home/JobOffer";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/`}
            component={AmericasRF}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/design`}
            component={DesignServices}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/deployment`}
            component={DeploymentServices}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/audit`}
            component={AuditServices}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/management`}
            component={ManagementServices}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/regulatory`}
            component={RegulatoryServices}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/professional`}
            component={ProfessionalServices}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/careers`}
            component={HomeParticles}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/careers/:jobId`}
            component={JobOffer}
          />

          <Route component={error404} />
        </Switch>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
