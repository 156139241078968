import React, { Component } from "react";
import { FiRss } from "react-icons/fi";

const ServiceList = [
  {
    icon: <FiRss />,
    title: "Network and Computer Systems Administrator/Transmission Engineer",
    description: "Chandler, Arizona.",
    link: "/careers/network-administrator",
  },
];

class ServiceThree extends Component {
  render() {
    const { column } = this.props;
    const ServiceContent = ServiceList.slice(0, this.props.item);

    return (
      <React.Fragment>
        <div className="row">
          {ServiceContent.map((val, i) => (
            <div className={`${column}`} key={i}>
              <a href={val.link}>
                <div className="service service__style--2">
                  <div className="icon">{val.icon}</div>
                  <div className="content">
                    <h3 className="title">{val.title}</h3>
                    <p>{val.description}</p>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceThree;
