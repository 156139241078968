import React, { Component } from "react";
//import CounterOne from "../../../elements/counters/CounterOne";

class About extends Component {
  render() {
    let title = "Who we are?",
      description =
        "Americas is recognized as an industry leader in providing telecom solutions to public and private sectors; targeting rural and tribal markets. " +
        " Based in Chandler, AZ. Americas Communications is certified as a small disadvantage, and minority owned business enterprise.",
      description3 =
        "Americas Communications supports all phases of telecom projects such as feasibility studies, preparation of requests for proposal, planning and design of telecommunications networks, evaluation and selection of projects and implementation.";
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/about/americas.jpg"
                    alt="About Images"
                  />
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{title}</h2>
                    <p className="description">{description}</p>
                    <br /> <p className="description">{description3}</p>
                  </div>

                  <div className="row mt--30 mt_sm--10">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Telecom expertise</h3>
                        <p>
                          Our broad expertise in mobile and fixed networks, as
                          well as microwave transmission design and deployment
                          makes Americas the desired integrator to support all
                          stages in the life cycle of telecom projects.
                        </p>
                      </div>
                    </div>
                    {/* <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Where we work</h3>
                        <p>
                          There are many vtions of passages of Lorem Ipsum
                          available, but the majority have suffered.
                        </p>
                      </div>
                    </div> */}
                  </div>

                  {/* <div className="row mt--30 mt_sm--10">
                    <CounterOne />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default About;
