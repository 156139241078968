import React, { Component } from "react";

import { FiX, FiMenu } from "react-icons/fi";
import Scrollspy from "react-scrollspy";

class HeaderThree extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  stickyHeader() {}

  render() {
    window.addEventListener("scroll", function () {
      var value = window.scrollY;

      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
        document.querySelector(".menutrigger").classList.add("main-color");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
        document.querySelector(".menutrigger").classList.remove("main-color");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    const { color = "default-color" } = this.props;
    let logoUrl = (
      <img
        src="/assets/images/logo/americas-logo.png"
        alt="Logo"
        height="70px"
      />
    );

    return (
      <header className={`header-area header-style-two header--fixed ${color}`}>
        <div className="header-wrapper">
          <div className="header-left d-flex align-items-center">
            <div className="logo">
              <a href={this.props.homeLink}>{logoUrl}</a>
            </div>
            <nav className="mainmenunav d-lg-block ml--50">
              <Scrollspy
                className="mainmenu"
                items={["home", "about", "service", "team", "contact"]}
                currentClassName="is-current"
                offset={-200}
              >
                <li>
                  <a href="#home">Home</a>
                </li>
                <li>
                  <a href="#about">About us</a>
                </li>
                <li>
                  <a href="#service">Services</a>
                </li>
                <li>
                  <a href="#team">Our team</a>
                </li>

                <li>
                  <a href="#contact">Contact</a>
                </li>

                <li>
                  <a href="/careers">Careers</a>
                </li>

                <li className="humberger-menu d-block d-lg-none">
                  <a
                    href="https://app.americasrf.com/index.php/login"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sign in
                  </a>
                </li>

                {/* <div className="d-flex justify-content-center ">
                  <a href={this.props.homeLink}>{logoUrl}</a>
                </div> */}
              </Scrollspy>
            </nav>
          </div>
          <div className="header-right">
            <div className="header-btn">
              <a
                className="rn-btn"
                href="https://app.americasrf.com/index.php/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Sign in</span>
              </a>
            </div>
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span onClick={this.menuTrigger} className="menutrigger">
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default HeaderThree;
