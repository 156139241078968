import React, { Component } from "react";
import { FaLinkedinIn } from "react-icons/fa";

let TeamContent = [
  {
    images: "01",
    title: "Bernardo Portilla",
    designation: "CEO Americas Communications",
    designation2: "bportilla@americasrf.com",
    designation3: "Phone: 602 304 1760 Ext 605",
    designation4: "Cell: 602 881 3147",
    socialNetwork: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/bportilla/",
      },
    ],
  },
  {
    images: "02",
    title: "Silvia P. Villota",
    designation: "CFO Americas Communications",
    designation2: "svillota@americasrf.com",
    designation3: "Tel: 602 304 1760 Ext 604",
    socialNetwork: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/silvia-p-villota-velasco-7119101/",
      },
    ],
  },
];

class Team extends Component {
  render() {
    const { column } = this.props;
    return (
      <React.Fragment>
        {TeamContent.map((value, i) => (
          <div className={`${column}`} key={i}>
            <div className="team">
              <div className="thumbnail">
                <img
                  src={`/assets/images/team/team-${value.images}.jpg`}
                  alt="Blog Images"
                />
              </div>
              <div className="content">
                <h4 className="title">{value.title}</h4>
                <p className="designation">{value.designation}</p>
                <p className="designation">{value.designation2}</p>
                <p className="designation">{value.designation3}</p>
                <p className="designation">{value.designation4}</p>
              </div>
              <ul className="social-icon">
                {value.socialNetwork.map((social, index) => (
                  <li key={index}>
                    <a
                      href={`${social.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {social.icon}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}
export default Team;
