import React, { Component } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderThreeCareers";
import FooterTwo from "../component/footer/FooterTwo";

const jobs = [
  {
    Id: "network-administrator",
    Employer: "Americas Communications d.b.a. Americas Communications, LLC",
    "Position Location": "Chandler, Arizona",
    Position:
      "Network and Computer Systems Administrator/Transmission Engineer",
    "Job Duties": `Applicant will install, configure, and support the network and internet systems designed
    by our organization. Applicant will assist in network modeling, analysis, planning and
    evaluation of design wireless networks 2G, 3G, and 4G. Applicant will select equipment
    including radio, cable and antennas, and Radio Frequency Simulation. Applicant will
    develop, design, license and implement microwave/fiber transmission projects.
    Applicant will also be involved in Capacity Calculation for transmission networks,
    detailed design and calculation for Microwave Path engineering, detailed capacity and
    reliability Calculations. Applicant will perform path and site surveys, field data
    calculation and verification of the environmental and terrain parameters. Applicant will
    participate in Configuration of Equipment. use of Pathloss 5.0, CISCO Routing, Microsoft
    Visio softwares, and Ceregon/Nera and Ericsson hardwares.`,
    Salary: "Available upon request",
    "Work Schedule": "40 hours/week",
    "Job Requirements": [
      {
        title: "Education Requirements",
        description: "Bachelor degree in Electronic Engineering",
      },
      {
        title: "Experience Requirements",
        description: `24 months in job offered, network and computer systems
    administrator/transmission engineer`,
      },
      {
        title: "Special skills requirements",
        description: `Pathloss 5.0, CISCO Routing, Microsoft Visio softwares, and Ceregon/Nera and Ericsson
    hardwares.`,
      },
    ],
    Notes: `Qualified applicants send cover letter and resumes to: Americas Communications d.b.a.
    Americas Communications, LLC, Silvia Villota, CFO at jobs@americasrf.com, and ref job# MF01.
    EOE.`,
  },
];

class JobOffer extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      currentJob: jobs.filter(
        (m) => m.Id === window.location.pathname.split("/")[2]
      )[0],
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <React.Fragment>
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--150 pb--120 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center">
                  <h2 className="title-white">
                    {this.state.currentJob.Position}
                  </h2>
                  <p>{this.state.currentJob["Position Location"]}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      {/* <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src="/assets/images/service/service-01.png"
                            alt="Service Images"
                          />
                        </div>
                      </div> */}
                      <div className="col-lg-12 col-12">
                        <div className="details mt_md--30 mt_sm--30 pb--30">
                          <h3 className="title">
                            {this.state.currentJob.Position}
                          </h3>
                        </div>
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title mt--30">Job description</h4>
                          <p>{this.state.currentJob["Job Duties"]}</p>
                        </div>
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title mt--30">Salary</h4>
                          <p>{this.state.currentJob.Salary}</p>
                        </div>
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title mt--30">Work Schedule</h4>
                          <p className="mt--0">
                            {this.state.currentJob["Work Schedule"]}
                          </p>
                        </div>
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title mt--30">Job requirements</h4>
                          <ul className="liststyle">
                            {this.state.currentJob["Job Requirements"].map(
                              (jr) => (
                                <li key={jr.title}>
                                  {jr.title}
                                  <ul>{jr.description}</ul>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title mt--30">Additional notes</h4>
                          <p>{this.state.currentJob["Notes"]}</p>
                        </div>
                        <div className="d-flex justify-content-end mt-5">
                          <div className="slide-btn">
                            <a
                              className="rn-button-style--3 btn-primary-color"
                              href="mailto:jobs@americasrf.com"
                            >
                              {"Apply now"}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area 
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">Salary</h4>
                          <p>{this.state.currentJob.Salary}</p>
                        </div>
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">Work Schedule</h4>
                          <p>{this.state.currentJob["Work Schedule"]}</p>
                        </div>
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">Job requirements</h4>
                          <ul className="liststyle">
                            {this.state.currentJob["Job Requirements"].map(
                              (jr) => (
                                <li key={jr.title}>
                                  {jr.title}
                                  <ul>{jr.description}</ul>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src="/assets/images/service/service-02.png"
                            alt="Service Images"
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <FooterTwo />
      </React.Fragment>
    );
  }
}

export default JobOffer;
